import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Directive, Attribute, Optional, SkipSelf, Component, ChangeDetectionStrategy, ViewChild, ContentChild, ContentChildren, inject, Injector, EnvironmentInjector, Injectable, APP_INITIALIZER, forwardRef, HostListener } from '@angular/core';
import * as i2$1 from '@ionic/angular/common';
import { IonRouterOutlet as IonRouterOutlet$1, ProxyCmp as ProxyCmp$1, IonBackButton as IonBackButton$1, IonModal as IonModal$1, IonPopover as IonPopover$1, RouterLinkDelegateDirective, RouterLinkWithHrefDelegateDirective, IonTabs as IonTabs$1, OverlayBaseController, AngularDelegate, ConfigToken, provideComponentInputBinding, MenuController as MenuController$1, IonNav as IonNav$1, ValueAccessor, setIonicClasses } from '@ionic/angular/common';
export { Config, DomController, IonicRouteStrategy, NavController, NavParams, Platform } from '@ionic/angular/common';
import { defineCustomElement as defineCustomElement$2 } from '@ionic/core/components/ion-back-button.js';
import { defineCustomElement as defineCustomElement$1 } from '@ionic/core/components/ion-router-outlet.js';
import * as i1 from '@angular/common';
import { CommonModule, DOCUMENT } from '@angular/common';
import * as i2 from '@angular/router';
import { defineCustomElement as defineCustomElement$3 } from '@ionic/core/components/ion-modal.js';
import { defineCustomElement as defineCustomElement$4 } from '@ionic/core/components/ion-popover.js';
import { fromEvent } from 'rxjs';
import { defineCustomElement as defineCustomElement$5 } from '@ionic/core/components/ion-accordion.js';
import { defineCustomElement as defineCustomElement$6 } from '@ionic/core/components/ion-accordion-group.js';
import { defineCustomElement as defineCustomElement$7 } from '@ionic/core/components/ion-action-sheet.js';
import { defineCustomElement as defineCustomElement$8 } from '@ionic/core/components/ion-alert.js';
import { defineCustomElement as defineCustomElement$9 } from '@ionic/core/components/ion-app.js';
import { defineCustomElement as defineCustomElement$a } from '@ionic/core/components/ion-avatar.js';
import { defineCustomElement as defineCustomElement$b } from '@ionic/core/components/ion-backdrop.js';
import { defineCustomElement as defineCustomElement$c } from '@ionic/core/components/ion-badge.js';
import { defineCustomElement as defineCustomElement$d } from '@ionic/core/components/ion-breadcrumb.js';
import { defineCustomElement as defineCustomElement$e } from '@ionic/core/components/ion-breadcrumbs.js';
import { defineCustomElement as defineCustomElement$f } from '@ionic/core/components/ion-button.js';
import { defineCustomElement as defineCustomElement$g } from '@ionic/core/components/ion-buttons.js';
import { defineCustomElement as defineCustomElement$h } from '@ionic/core/components/ion-card.js';
import { defineCustomElement as defineCustomElement$i } from '@ionic/core/components/ion-card-content.js';
import { defineCustomElement as defineCustomElement$j } from '@ionic/core/components/ion-card-header.js';
import { defineCustomElement as defineCustomElement$k } from '@ionic/core/components/ion-card-subtitle.js';
import { defineCustomElement as defineCustomElement$l } from '@ionic/core/components/ion-card-title.js';
import { defineCustomElement as defineCustomElement$m } from '@ionic/core/components/ion-chip.js';
import { defineCustomElement as defineCustomElement$n } from '@ionic/core/components/ion-col.js';
import { defineCustomElement as defineCustomElement$o } from '@ionic/core/components/ion-content.js';
import { defineCustomElement as defineCustomElement$p } from '@ionic/core/components/ion-datetime-button.js';
import { defineCustomElement as defineCustomElement$q } from '@ionic/core/components/ion-fab.js';
import { defineCustomElement as defineCustomElement$r } from '@ionic/core/components/ion-fab-button.js';
import { defineCustomElement as defineCustomElement$s } from '@ionic/core/components/ion-fab-list.js';
import { defineCustomElement as defineCustomElement$t } from '@ionic/core/components/ion-footer.js';
import { defineCustomElement as defineCustomElement$u } from '@ionic/core/components/ion-grid.js';
import { defineCustomElement as defineCustomElement$v } from '@ionic/core/components/ion-header.js';
import { defineCustomElement as defineCustomElement$w } from '@ionic/core/components/ion-img.js';
import { defineCustomElement as defineCustomElement$x } from '@ionic/core/components/ion-infinite-scroll.js';
import { defineCustomElement as defineCustomElement$y } from '@ionic/core/components/ion-infinite-scroll-content.js';
import { defineCustomElement as defineCustomElement$z } from '@ionic/core/components/ion-item.js';
import { defineCustomElement as defineCustomElement$A } from '@ionic/core/components/ion-item-divider.js';
import { defineCustomElement as defineCustomElement$B } from '@ionic/core/components/ion-item-group.js';
import { defineCustomElement as defineCustomElement$C } from '@ionic/core/components/ion-item-option.js';
import { defineCustomElement as defineCustomElement$D } from '@ionic/core/components/ion-item-options.js';
import { defineCustomElement as defineCustomElement$E } from '@ionic/core/components/ion-item-sliding.js';
import { defineCustomElement as defineCustomElement$F } from '@ionic/core/components/ion-label.js';
import { defineCustomElement as defineCustomElement$G } from '@ionic/core/components/ion-list.js';
import { defineCustomElement as defineCustomElement$H } from '@ionic/core/components/ion-list-header.js';
import { defineCustomElement as defineCustomElement$I } from '@ionic/core/components/ion-loading.js';
import { defineCustomElement as defineCustomElement$J } from '@ionic/core/components/ion-menu.js';
import { defineCustomElement as defineCustomElement$K } from '@ionic/core/components/ion-menu-button.js';
import { defineCustomElement as defineCustomElement$L } from '@ionic/core/components/ion-menu-toggle.js';
import { defineCustomElement as defineCustomElement$M } from '@ionic/core/components/ion-nav-link.js';
import { defineCustomElement as defineCustomElement$N } from '@ionic/core/components/ion-note.js';
import { defineCustomElement as defineCustomElement$O } from '@ionic/core/components/ion-picker.js';
import { defineCustomElement as defineCustomElement$P } from '@ionic/core/components/ion-progress-bar.js';
import { defineCustomElement as defineCustomElement$Q } from '@ionic/core/components/ion-refresher.js';
import { defineCustomElement as defineCustomElement$R } from '@ionic/core/components/ion-refresher-content.js';
import { defineCustomElement as defineCustomElement$S } from '@ionic/core/components/ion-reorder.js';
import { defineCustomElement as defineCustomElement$T } from '@ionic/core/components/ion-reorder-group.js';
import { defineCustomElement as defineCustomElement$U } from '@ionic/core/components/ion-ripple-effect.js';
import { defineCustomElement as defineCustomElement$V } from '@ionic/core/components/ion-row.js';
import { defineCustomElement as defineCustomElement$W } from '@ionic/core/components/ion-segment-button.js';
import { defineCustomElement as defineCustomElement$X } from '@ionic/core/components/ion-select-option.js';
import { defineCustomElement as defineCustomElement$Y } from '@ionic/core/components/ion-skeleton-text.js';
import { defineCustomElement as defineCustomElement$Z } from '@ionic/core/components/ion-spinner.js';
import { defineCustomElement as defineCustomElement$_ } from '@ionic/core/components/ion-split-pane.js';
import { defineCustomElement as defineCustomElement$$ } from '@ionic/core/components/ion-tab-bar.js';
import { defineCustomElement as defineCustomElement$10 } from '@ionic/core/components/ion-tab-button.js';
import { defineCustomElement as defineCustomElement$11 } from '@ionic/core/components/ion-text.js';
import { defineCustomElement as defineCustomElement$12 } from '@ionic/core/components/ion-thumbnail.js';
import { defineCustomElement as defineCustomElement$13 } from '@ionic/core/components/ion-title.js';
import { defineCustomElement as defineCustomElement$14 } from '@ionic/core/components/ion-toast.js';
import { defineCustomElement as defineCustomElement$15 } from '@ionic/core/components/ion-toolbar.js';
import { modalController, popoverController, initialize, actionSheetController, alertController, createAnimation, getTimeGivenProgression, createGesture, loadingController, menuController, pickerController, toastController } from '@ionic/core/components';
export { IonicSafeString, IonicSlides, createAnimation, createGesture, getPlatforms, getTimeGivenProgression, iosTransitionAnimation, isPlatform, mdTransitionAnimation } from '@ionic/core/components';
import { defineCustomElement as defineCustomElement$16 } from '@ionic/core/components/ion-nav.js';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { defineCustomElement as defineCustomElement$17 } from '@ionic/core/components/ion-checkbox.js';
import { defineCustomElement as defineCustomElement$18 } from '@ionic/core/components/ion-datetime.js';
import { defineCustomElement as defineCustomElement$19 } from 'ionicons/components/ion-icon.js';
import { defineCustomElement as defineCustomElement$1a } from '@ionic/core/components/ion-input.js';
import { defineCustomElement as defineCustomElement$1b } from '@ionic/core/components/ion-radio-group.js';
import { defineCustomElement as defineCustomElement$1c } from '@ionic/core/components/ion-radio.js';
import { defineCustomElement as defineCustomElement$1d } from '@ionic/core/components/ion-range.js';
import { defineCustomElement as defineCustomElement$1e } from '@ionic/core/components/ion-searchbar.js';
import { defineCustomElement as defineCustomElement$1f } from '@ionic/core/components/ion-segment.js';
import { defineCustomElement as defineCustomElement$1g } from '@ionic/core/components/ion-select.js';
import { defineCustomElement as defineCustomElement$1h } from '@ionic/core/components/ion-textarea.js';
import { defineCustomElement as defineCustomElement$1i } from '@ionic/core/components/ion-toggle.js';
let IonRouterOutlet = class IonRouterOutlet extends IonRouterOutlet$1 {
  /**
   * We need to pass in the correct instance of IonRouterOutlet
   * otherwise parentOutlet will be null in a nested outlet context.
   * This results in APIs such as NavController.pop not working
   * in nested outlets because the parent outlet cannot be found.
   */
  constructor(name, tabs, commonLocation, elementRef, router, zone, activatedRoute, parentOutlet) {
    super(name, tabs, commonLocation, elementRef, router, zone, activatedRoute, parentOutlet);
    this.parentOutlet = parentOutlet;
  }
};
/** @nocollapse */
IonRouterOutlet.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRouterOutlet,
  deps: [{
    token: 'name',
    attribute: true
  }, {
    token: 'tabs',
    attribute: true,
    optional: true
  }, {
    token: i1.Location
  }, {
    token: i0.ElementRef
  }, {
    token: i2.Router
  }, {
    token: i0.NgZone
  }, {
    token: i2.ActivatedRoute
  }, {
    token: IonRouterOutlet,
    optional: true,
    skipSelf: true
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
IonRouterOutlet.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRouterOutlet,
  isStandalone: true,
  selector: "ion-router-outlet",
  usesInheritance: true,
  ngImport: i0
});
IonRouterOutlet = __decorate([ProxyCmp$1({
  defineCustomElementFn: defineCustomElement$1
})], IonRouterOutlet);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRouterOutlet,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ion-router-outlet',
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: undefined,
      decorators: [{
        type: Attribute,
        args: ['name']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Attribute,
        args: ['tabs']
      }]
    }, {
      type: i1.Location
    }, {
      type: i0.ElementRef
    }, {
      type: i2.Router
    }, {
      type: i0.NgZone
    }, {
      type: i2.ActivatedRoute
    }, {
      type: IonRouterOutlet,
      decorators: [{
        type: SkipSelf
      }, {
        type: Optional
      }]
    }];
  }
});
let IonBackButton = class IonBackButton extends IonBackButton$1 {
  constructor(routerOutlet, navCtrl, config, r, z, c) {
    super(routerOutlet, navCtrl, config, r, z, c);
  }
};
/** @nocollapse */
IonBackButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBackButton,
  deps: [{
    token: IonRouterOutlet,
    optional: true
  }, {
    token: i2$1.NavController
  }, {
    token: i2$1.Config
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }, {
    token: i0.ChangeDetectorRef
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonBackButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonBackButton,
  isStandalone: true,
  selector: "ion-back-button",
  usesInheritance: true,
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonBackButton = __decorate([ProxyCmp$1({
  defineCustomElementFn: defineCustomElement$2
})], IonBackButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBackButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-back-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: IonRouterOutlet,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i2$1.NavController
    }, {
      type: i2$1.Config
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.ChangeDetectorRef
    }];
  }
});
let IonModal = class IonModal extends IonModal$1 {};
/** @nocollapse */
IonModal.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonModal,
  deps: null,
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonModal.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonModal,
  isStandalone: true,
  selector: "ion-modal",
  usesInheritance: true,
  ngImport: i0,
  template: `<div class="ion-delegate-host ion-page" *ngIf="isCmpOpen || keepContentsMounted">
    <ng-container [ngTemplateOutlet]="template"></ng-container>
  </div>`,
  isInline: true,
  dependencies: [{
    kind: "ngmodule",
    type: CommonModule
  }, {
    kind: "directive",
    type: i1.NgIf,
    selector: "[ngIf]",
    inputs: ["ngIf", "ngIfThen", "ngIfElse"]
  }, {
    kind: "directive",
    type: i1.NgTemplateOutlet,
    selector: "[ngTemplateOutlet]",
    inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
  }],
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonModal = __decorate([ProxyCmp$1({
  defineCustomElementFn: defineCustomElement$3
})], IonModal);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonModal,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-modal',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: `<div class="ion-delegate-host ion-page" *ngIf="isCmpOpen || keepContentsMounted">
    <ng-container [ngTemplateOutlet]="template"></ng-container>
  </div>`,
      standalone: true,
      imports: [CommonModule]
    }]
  }]
});
let IonPopover = class IonPopover extends IonPopover$1 {};
/** @nocollapse */
IonPopover.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonPopover,
  deps: null,
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonPopover.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonPopover,
  isStandalone: true,
  selector: "ion-popover",
  usesInheritance: true,
  ngImport: i0,
  template: `<ng-container [ngTemplateOutlet]="template" *ngIf="isCmpOpen || keepContentsMounted"></ng-container>`,
  isInline: true,
  dependencies: [{
    kind: "ngmodule",
    type: CommonModule
  }, {
    kind: "directive",
    type: i1.NgIf,
    selector: "[ngIf]",
    inputs: ["ngIf", "ngIfThen", "ngIfElse"]
  }, {
    kind: "directive",
    type: i1.NgTemplateOutlet,
    selector: "[ngTemplateOutlet]",
    inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
  }],
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonPopover = __decorate([ProxyCmp$1({
  defineCustomElementFn: defineCustomElement$4
})], IonPopover);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonPopover,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-popover',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: `<ng-container [ngTemplateOutlet]="template" *ngIf="isCmpOpen || keepContentsMounted"></ng-container>`,
      standalone: true,
      imports: [CommonModule]
    }]
  }]
});

// eslint-disable-next-line @angular-eslint/directive-class-suffix
class IonRouterLink extends RouterLinkDelegateDirective {}
/** @nocollapse */
IonRouterLink.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRouterLink,
  deps: null,
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
IonRouterLink.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRouterLink,
  isStandalone: true,
  selector: ":not(a):not(area)[routerLink]",
  usesInheritance: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRouterLink,
  decorators: [{
    type: Directive,
    args: [{
      selector: ':not(a):not(area)[routerLink]',
      standalone: true
    }]
  }]
});
// eslint-disable-next-line @angular-eslint/directive-class-suffix
class IonRouterLinkWithHref extends RouterLinkWithHrefDelegateDirective {}
/** @nocollapse */
IonRouterLinkWithHref.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRouterLinkWithHref,
  deps: null,
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
IonRouterLinkWithHref.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRouterLinkWithHref,
  isStandalone: true,
  selector: "a[routerLink],area[routerLink]",
  usesInheritance: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRouterLinkWithHref,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'a[routerLink],area[routerLink]',
      standalone: true
    }]
  }]
});

/* eslint-disable */
const proxyInputs = (Cmp, inputs) => {
  const Prototype = Cmp.prototype;
  inputs.forEach(item => {
    Object.defineProperty(Prototype, item, {
      get() {
        return this.el[item];
      },
      set(val) {
        this.z.runOutsideAngular(() => this.el[item] = val);
      },
      /**
       * In the event that proxyInputs is called
       * multiple times re-defining these inputs
       * will cause an error to be thrown. As a result
       * we set configurable: true to indicate these
       * properties can be changed.
       */
      configurable: true
    });
  });
};
const proxyMethods = (Cmp, methods) => {
  const Prototype = Cmp.prototype;
  methods.forEach(methodName => {
    Prototype[methodName] = function () {
      const args = arguments;
      return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
    };
  });
};
const proxyOutputs = (instance, el, events) => {
  events.forEach(eventName => instance[eventName] = fromEvent(el, eventName));
};
const defineCustomElement = (tagName, customElement) => {
  if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
    customElements.define(tagName, customElement);
  }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
  const decorator = function (cls) {
    const {
      defineCustomElementFn,
      inputs,
      methods
    } = opts;
    if (defineCustomElementFn !== undefined) {
      defineCustomElementFn();
    }
    if (inputs) {
      proxyInputs(cls, inputs);
    }
    if (methods) {
      proxyMethods(cls, methods);
    }
    return cls;
  };
  return decorator;
}
let IonAccordion = class IonAccordion {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonAccordion.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonAccordion,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonAccordion.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonAccordion,
  isStandalone: true,
  selector: "ion-accordion",
  inputs: {
    disabled: "disabled",
    mode: "mode",
    readonly: "readonly",
    toggleIcon: "toggleIcon",
    toggleIconSlot: "toggleIconSlot",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonAccordion = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$5,
  inputs: ['disabled', 'mode', 'readonly', 'toggleIcon', 'toggleIconSlot', 'value']
})], IonAccordion);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonAccordion,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-accordion',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'mode', 'readonly', 'toggleIcon', 'toggleIconSlot', 'value'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonAccordionGroup = class IonAccordionGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange']);
  }
};
/** @nocollapse */
IonAccordionGroup.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonAccordionGroup,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonAccordionGroup.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonAccordionGroup,
  isStandalone: true,
  selector: "ion-accordion-group",
  inputs: {
    animated: "animated",
    disabled: "disabled",
    expand: "expand",
    mode: "mode",
    multiple: "multiple",
    readonly: "readonly",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonAccordionGroup = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$6,
  inputs: ['animated', 'disabled', 'expand', 'mode', 'multiple', 'readonly', 'value']
})], IonAccordionGroup);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonAccordionGroup,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-accordion-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'disabled', 'expand', 'mode', 'multiple', 'readonly', 'value'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonActionSheet = class IonActionSheet {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionActionSheetDidPresent', 'ionActionSheetWillPresent', 'ionActionSheetWillDismiss', 'ionActionSheetDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonActionSheet.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonActionSheet,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonActionSheet.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonActionSheet,
  isStandalone: true,
  selector: "ion-action-sheet",
  inputs: {
    animated: "animated",
    backdropDismiss: "backdropDismiss",
    buttons: "buttons",
    cssClass: "cssClass",
    enterAnimation: "enterAnimation",
    header: "header",
    htmlAttributes: "htmlAttributes",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    mode: "mode",
    subHeader: "subHeader",
    translucent: "translucent",
    trigger: "trigger"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonActionSheet = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$7,
  inputs: ['animated', 'backdropDismiss', 'buttons', 'cssClass', 'enterAnimation', 'header', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'subHeader', 'translucent', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss']
})], IonActionSheet);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonActionSheet,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-action-sheet',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'backdropDismiss', 'buttons', 'cssClass', 'enterAnimation', 'header', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'subHeader', 'translucent', 'trigger'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonAlert = class IonAlert {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionAlertDidPresent', 'ionAlertWillPresent', 'ionAlertWillDismiss', 'ionAlertDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonAlert.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonAlert,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonAlert.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonAlert,
  isStandalone: true,
  selector: "ion-alert",
  inputs: {
    animated: "animated",
    backdropDismiss: "backdropDismiss",
    buttons: "buttons",
    cssClass: "cssClass",
    enterAnimation: "enterAnimation",
    header: "header",
    htmlAttributes: "htmlAttributes",
    inputs: "inputs",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    message: "message",
    mode: "mode",
    subHeader: "subHeader",
    translucent: "translucent",
    trigger: "trigger"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonAlert = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$8,
  inputs: ['animated', 'backdropDismiss', 'buttons', 'cssClass', 'enterAnimation', 'header', 'htmlAttributes', 'inputs', 'isOpen', 'keyboardClose', 'leaveAnimation', 'message', 'mode', 'subHeader', 'translucent', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss']
})], IonAlert);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonAlert,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-alert',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'backdropDismiss', 'buttons', 'cssClass', 'enterAnimation', 'header', 'htmlAttributes', 'inputs', 'isOpen', 'keyboardClose', 'leaveAnimation', 'message', 'mode', 'subHeader', 'translucent', 'trigger'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonApp = class IonApp {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonApp.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonApp,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonApp.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonApp,
  isStandalone: true,
  selector: "ion-app",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonApp = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$9
})], IonApp);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonApp,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-app',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonAvatar = class IonAvatar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonAvatar.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonAvatar,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonAvatar.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonAvatar,
  isStandalone: true,
  selector: "ion-avatar",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonAvatar = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$a
})], IonAvatar);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonAvatar,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-avatar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonBackdrop = class IonBackdrop {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionBackdropTap']);
  }
};
/** @nocollapse */
IonBackdrop.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBackdrop,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonBackdrop.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonBackdrop,
  isStandalone: true,
  selector: "ion-backdrop",
  inputs: {
    stopPropagation: "stopPropagation",
    tappable: "tappable",
    visible: "visible"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonBackdrop = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$b,
  inputs: ['stopPropagation', 'tappable', 'visible']
})], IonBackdrop);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBackdrop,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-backdrop',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['stopPropagation', 'tappable', 'visible'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonBadge = class IonBadge {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonBadge.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBadge,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonBadge.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonBadge,
  isStandalone: true,
  selector: "ion-badge",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonBadge = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$c,
  inputs: ['color', 'mode']
})], IonBadge);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBadge,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-badge',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonBreadcrumb = class IonBreadcrumb {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonBreadcrumb.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBreadcrumb,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonBreadcrumb.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonBreadcrumb,
  isStandalone: true,
  selector: "ion-breadcrumb",
  inputs: {
    active: "active",
    color: "color",
    disabled: "disabled",
    download: "download",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    separator: "separator",
    target: "target"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonBreadcrumb = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$d,
  inputs: ['active', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'separator', 'target']
})], IonBreadcrumb);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBreadcrumb,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-breadcrumb',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['active', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'separator', 'target'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonBreadcrumbs = class IonBreadcrumbs {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionCollapsedClick']);
  }
};
/** @nocollapse */
IonBreadcrumbs.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBreadcrumbs,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonBreadcrumbs.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonBreadcrumbs,
  isStandalone: true,
  selector: "ion-breadcrumbs",
  inputs: {
    color: "color",
    itemsAfterCollapse: "itemsAfterCollapse",
    itemsBeforeCollapse: "itemsBeforeCollapse",
    maxItems: "maxItems",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonBreadcrumbs = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$e,
  inputs: ['color', 'itemsAfterCollapse', 'itemsBeforeCollapse', 'maxItems', 'mode']
})], IonBreadcrumbs);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBreadcrumbs,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-breadcrumbs',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'itemsAfterCollapse', 'itemsBeforeCollapse', 'maxItems', 'mode'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonButton = class IonButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonButton,
  isStandalone: true,
  selector: "ion-button",
  inputs: {
    buttonType: "buttonType",
    color: "color",
    disabled: "disabled",
    download: "download",
    expand: "expand",
    fill: "fill",
    form: "form",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    shape: "shape",
    size: "size",
    strong: "strong",
    target: "target",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonButton = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$f,
  inputs: ['buttonType', 'color', 'disabled', 'download', 'expand', 'fill', 'form', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'size', 'strong', 'target', 'type']
})], IonButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['buttonType', 'color', 'disabled', 'download', 'expand', 'fill', 'form', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'size', 'strong', 'target', 'type'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonButtons = class IonButtons {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonButtons.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonButtons,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonButtons.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonButtons,
  isStandalone: true,
  selector: "ion-buttons",
  inputs: {
    collapse: "collapse"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonButtons = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$g,
  inputs: ['collapse']
})], IonButtons);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonButtons,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-buttons',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['collapse'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCard = class IonCard {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCard.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCard,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCard.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonCard,
  isStandalone: true,
  selector: "ion-card",
  inputs: {
    button: "button",
    color: "color",
    disabled: "disabled",
    download: "download",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    target: "target",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCard = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$h,
  inputs: ['button', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'target', 'type']
})], IonCard);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCard,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-card',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['button', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'target', 'type'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCardContent = class IonCardContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardContent.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCardContent,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCardContent.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonCardContent,
  isStandalone: true,
  selector: "ion-card-content",
  inputs: {
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCardContent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$i,
  inputs: ['mode']
})], IonCardContent);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCardContent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-card-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['mode'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCardHeader = class IonCardHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardHeader.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCardHeader,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCardHeader.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonCardHeader,
  isStandalone: true,
  selector: "ion-card-header",
  inputs: {
    color: "color",
    mode: "mode",
    translucent: "translucent"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCardHeader = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$j,
  inputs: ['color', 'mode', 'translucent']
})], IonCardHeader);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCardHeader,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-card-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode', 'translucent'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCardSubtitle = class IonCardSubtitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardSubtitle.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCardSubtitle,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCardSubtitle.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonCardSubtitle,
  isStandalone: true,
  selector: "ion-card-subtitle",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCardSubtitle = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$k,
  inputs: ['color', 'mode']
})], IonCardSubtitle);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCardSubtitle,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-card-subtitle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCardTitle = class IonCardTitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardTitle.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCardTitle,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCardTitle.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonCardTitle,
  isStandalone: true,
  selector: "ion-card-title",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCardTitle = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$l,
  inputs: ['color', 'mode']
})], IonCardTitle);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCardTitle,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-card-title',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonChip = class IonChip {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonChip.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonChip,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonChip.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonChip,
  isStandalone: true,
  selector: "ion-chip",
  inputs: {
    color: "color",
    disabled: "disabled",
    mode: "mode",
    outline: "outline"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonChip = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$m,
  inputs: ['color', 'disabled', 'mode', 'outline']
})], IonChip);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonChip,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-chip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'disabled', 'mode', 'outline'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCol = class IonCol {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCol.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCol,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCol.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonCol,
  isStandalone: true,
  selector: "ion-col",
  inputs: {
    offset: "offset",
    offsetLg: "offsetLg",
    offsetMd: "offsetMd",
    offsetSm: "offsetSm",
    offsetXl: "offsetXl",
    offsetXs: "offsetXs",
    pull: "pull",
    pullLg: "pullLg",
    pullMd: "pullMd",
    pullSm: "pullSm",
    pullXl: "pullXl",
    pullXs: "pullXs",
    push: "push",
    pushLg: "pushLg",
    pushMd: "pushMd",
    pushSm: "pushSm",
    pushXl: "pushXl",
    pushXs: "pushXs",
    size: "size",
    sizeLg: "sizeLg",
    sizeMd: "sizeMd",
    sizeSm: "sizeSm",
    sizeXl: "sizeXl",
    sizeXs: "sizeXs"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCol = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$n,
  inputs: ['offset', 'offsetLg', 'offsetMd', 'offsetSm', 'offsetXl', 'offsetXs', 'pull', 'pullLg', 'pullMd', 'pullSm', 'pullXl', 'pullXs', 'push', 'pushLg', 'pushMd', 'pushSm', 'pushXl', 'pushXs', 'size', 'sizeLg', 'sizeMd', 'sizeSm', 'sizeXl', 'sizeXs']
})], IonCol);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCol,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-col',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['offset', 'offsetLg', 'offsetMd', 'offsetSm', 'offsetXl', 'offsetXs', 'pull', 'pullLg', 'pullMd', 'pullSm', 'pullXl', 'pullXs', 'push', 'pushLg', 'pushMd', 'pushSm', 'pushXl', 'pushXs', 'size', 'sizeLg', 'sizeMd', 'sizeSm', 'sizeXl', 'sizeXs'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonContent = class IonContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionScrollStart', 'ionScroll', 'ionScrollEnd']);
  }
};
/** @nocollapse */
IonContent.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonContent,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonContent.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonContent,
  isStandalone: true,
  selector: "ion-content",
  inputs: {
    color: "color",
    forceOverscroll: "forceOverscroll",
    fullscreen: "fullscreen",
    scrollEvents: "scrollEvents",
    scrollX: "scrollX",
    scrollY: "scrollY"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonContent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$o,
  inputs: ['color', 'forceOverscroll', 'fullscreen', 'scrollEvents', 'scrollX', 'scrollY'],
  methods: ['getScrollElement', 'scrollToTop', 'scrollToBottom', 'scrollByPoint', 'scrollToPoint']
})], IonContent);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonContent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'forceOverscroll', 'fullscreen', 'scrollEvents', 'scrollX', 'scrollY'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonDatetimeButton = class IonDatetimeButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonDatetimeButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonDatetimeButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonDatetimeButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonDatetimeButton,
  isStandalone: true,
  selector: "ion-datetime-button",
  inputs: {
    color: "color",
    datetime: "datetime",
    disabled: "disabled",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonDatetimeButton = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$p,
  inputs: ['color', 'datetime', 'disabled', 'mode']
})], IonDatetimeButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonDatetimeButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-datetime-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'datetime', 'disabled', 'mode'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonFab = class IonFab {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonFab.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonFab,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonFab.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonFab,
  isStandalone: true,
  selector: "ion-fab",
  inputs: {
    activated: "activated",
    edge: "edge",
    horizontal: "horizontal",
    vertical: "vertical"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonFab = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$q,
  inputs: ['activated', 'edge', 'horizontal', 'vertical'],
  methods: ['close']
})], IonFab);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonFab,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-fab',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activated', 'edge', 'horizontal', 'vertical'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonFabButton = class IonFabButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonFabButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonFabButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonFabButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonFabButton,
  isStandalone: true,
  selector: "ion-fab-button",
  inputs: {
    activated: "activated",
    closeIcon: "closeIcon",
    color: "color",
    disabled: "disabled",
    download: "download",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    show: "show",
    size: "size",
    target: "target",
    translucent: "translucent",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonFabButton = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$r,
  inputs: ['activated', 'closeIcon', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'show', 'size', 'target', 'translucent', 'type']
})], IonFabButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonFabButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-fab-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activated', 'closeIcon', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'show', 'size', 'target', 'translucent', 'type'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonFabList = class IonFabList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonFabList.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonFabList,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonFabList.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonFabList,
  isStandalone: true,
  selector: "ion-fab-list",
  inputs: {
    activated: "activated",
    side: "side"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonFabList = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$s,
  inputs: ['activated', 'side']
})], IonFabList);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonFabList,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-fab-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activated', 'side'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonFooter = class IonFooter {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonFooter.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonFooter,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonFooter.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonFooter,
  isStandalone: true,
  selector: "ion-footer",
  inputs: {
    collapse: "collapse",
    mode: "mode",
    translucent: "translucent"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonFooter = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$t,
  inputs: ['collapse', 'mode', 'translucent']
})], IonFooter);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonFooter,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-footer',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['collapse', 'mode', 'translucent'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonGrid = class IonGrid {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonGrid.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonGrid,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonGrid.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonGrid,
  isStandalone: true,
  selector: "ion-grid",
  inputs: {
    fixed: "fixed"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonGrid = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$u,
  inputs: ['fixed']
})], IonGrid);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonGrid,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-grid',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['fixed'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonHeader = class IonHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonHeader.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonHeader,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonHeader.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonHeader,
  isStandalone: true,
  selector: "ion-header",
  inputs: {
    collapse: "collapse",
    mode: "mode",
    translucent: "translucent"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonHeader = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$v,
  inputs: ['collapse', 'mode', 'translucent']
})], IonHeader);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonHeader,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['collapse', 'mode', 'translucent'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonImg = class IonImg {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionImgWillLoad', 'ionImgDidLoad', 'ionError']);
  }
};
/** @nocollapse */
IonImg.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonImg,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonImg.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonImg,
  isStandalone: true,
  selector: "ion-img",
  inputs: {
    alt: "alt",
    src: "src"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonImg = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$w,
  inputs: ['alt', 'src']
})], IonImg);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonImg,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-img',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['alt', 'src'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonInfiniteScroll = class IonInfiniteScroll {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionInfinite']);
  }
};
/** @nocollapse */
IonInfiniteScroll.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonInfiniteScroll,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonInfiniteScroll.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonInfiniteScroll,
  isStandalone: true,
  selector: "ion-infinite-scroll",
  inputs: {
    disabled: "disabled",
    position: "position",
    threshold: "threshold"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonInfiniteScroll = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$x,
  inputs: ['disabled', 'position', 'threshold'],
  methods: ['complete']
})], IonInfiniteScroll);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonInfiniteScroll,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-infinite-scroll',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'position', 'threshold'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonInfiniteScrollContent = class IonInfiniteScrollContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonInfiniteScrollContent.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonInfiniteScrollContent,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonInfiniteScrollContent.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonInfiniteScrollContent,
  isStandalone: true,
  selector: "ion-infinite-scroll-content",
  inputs: {
    loadingSpinner: "loadingSpinner",
    loadingText: "loadingText"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonInfiniteScrollContent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$y,
  inputs: ['loadingSpinner', 'loadingText']
})], IonInfiniteScrollContent);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonInfiniteScrollContent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-infinite-scroll-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['loadingSpinner', 'loadingText'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItem = class IonItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItem.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItem,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItem.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonItem,
  isStandalone: true,
  selector: "ion-item",
  inputs: {
    button: "button",
    color: "color",
    counter: "counter",
    counterFormatter: "counterFormatter",
    detail: "detail",
    detailIcon: "detailIcon",
    disabled: "disabled",
    download: "download",
    fill: "fill",
    href: "href",
    lines: "lines",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    shape: "shape",
    target: "target",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItem = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$z,
  inputs: ['button', 'color', 'counter', 'counterFormatter', 'detail', 'detailIcon', 'disabled', 'download', 'fill', 'href', 'lines', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'target', 'type']
})], IonItem);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItem,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['button', 'color', 'counter', 'counterFormatter', 'detail', 'detailIcon', 'disabled', 'download', 'fill', 'href', 'lines', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'target', 'type'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItemDivider = class IonItemDivider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItemDivider.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemDivider,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItemDivider.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonItemDivider,
  isStandalone: true,
  selector: "ion-item-divider",
  inputs: {
    color: "color",
    mode: "mode",
    sticky: "sticky"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItemDivider = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$A,
  inputs: ['color', 'mode', 'sticky']
})], IonItemDivider);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemDivider,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item-divider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode', 'sticky'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItemGroup = class IonItemGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItemGroup.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemGroup,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItemGroup.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonItemGroup,
  isStandalone: true,
  selector: "ion-item-group",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItemGroup = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$B
})], IonItemGroup);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemGroup,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItemOption = class IonItemOption {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItemOption.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemOption,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItemOption.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonItemOption,
  isStandalone: true,
  selector: "ion-item-option",
  inputs: {
    color: "color",
    disabled: "disabled",
    download: "download",
    expandable: "expandable",
    href: "href",
    mode: "mode",
    rel: "rel",
    target: "target",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItemOption = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$C,
  inputs: ['color', 'disabled', 'download', 'expandable', 'href', 'mode', 'rel', 'target', 'type']
})], IonItemOption);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemOption,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item-option',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'disabled', 'download', 'expandable', 'href', 'mode', 'rel', 'target', 'type'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItemOptions = class IonItemOptions {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionSwipe']);
  }
};
/** @nocollapse */
IonItemOptions.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemOptions,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItemOptions.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonItemOptions,
  isStandalone: true,
  selector: "ion-item-options",
  inputs: {
    side: "side"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItemOptions = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$D,
  inputs: ['side']
})], IonItemOptions);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemOptions,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item-options',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['side'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItemSliding = class IonItemSliding {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionDrag']);
  }
};
/** @nocollapse */
IonItemSliding.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemSliding,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItemSliding.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonItemSliding,
  isStandalone: true,
  selector: "ion-item-sliding",
  inputs: {
    disabled: "disabled"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItemSliding = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$E,
  inputs: ['disabled'],
  methods: ['getOpenAmount', 'getSlidingRatio', 'open', 'close', 'closeOpened']
})], IonItemSliding);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemSliding,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item-sliding',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonLabel = class IonLabel {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonLabel.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonLabel,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonLabel.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonLabel,
  isStandalone: true,
  selector: "ion-label",
  inputs: {
    color: "color",
    mode: "mode",
    position: "position"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonLabel = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$F,
  inputs: ['color', 'mode', 'position']
})], IonLabel);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonLabel,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-label',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode', 'position'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonList = class IonList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonList.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonList,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonList.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonList,
  isStandalone: true,
  selector: "ion-list",
  inputs: {
    inset: "inset",
    lines: "lines",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonList = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$G,
  inputs: ['inset', 'lines', 'mode'],
  methods: ['closeSlidingItems']
})], IonList);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonList,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['inset', 'lines', 'mode'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonListHeader = class IonListHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonListHeader.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonListHeader,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonListHeader.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonListHeader,
  isStandalone: true,
  selector: "ion-list-header",
  inputs: {
    color: "color",
    lines: "lines",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonListHeader = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$H,
  inputs: ['color', 'lines', 'mode']
})], IonListHeader);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonListHeader,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-list-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'lines', 'mode'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonLoading = class IonLoading {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionLoadingDidPresent', 'ionLoadingWillPresent', 'ionLoadingWillDismiss', 'ionLoadingDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonLoading.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonLoading,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonLoading.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonLoading,
  isStandalone: true,
  selector: "ion-loading",
  inputs: {
    animated: "animated",
    backdropDismiss: "backdropDismiss",
    cssClass: "cssClass",
    duration: "duration",
    enterAnimation: "enterAnimation",
    htmlAttributes: "htmlAttributes",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    message: "message",
    mode: "mode",
    showBackdrop: "showBackdrop",
    spinner: "spinner",
    translucent: "translucent",
    trigger: "trigger"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonLoading = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$I,
  inputs: ['animated', 'backdropDismiss', 'cssClass', 'duration', 'enterAnimation', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'message', 'mode', 'showBackdrop', 'spinner', 'translucent', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss']
})], IonLoading);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonLoading,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-loading',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'backdropDismiss', 'cssClass', 'duration', 'enterAnimation', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'message', 'mode', 'showBackdrop', 'spinner', 'translucent', 'trigger'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonMenu = class IonMenu {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionWillOpen', 'ionWillClose', 'ionDidOpen', 'ionDidClose']);
  }
};
/** @nocollapse */
IonMenu.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonMenu,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonMenu.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonMenu,
  isStandalone: true,
  selector: "ion-menu",
  inputs: {
    contentId: "contentId",
    disabled: "disabled",
    maxEdgeStart: "maxEdgeStart",
    menuId: "menuId",
    side: "side",
    swipeGesture: "swipeGesture",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonMenu = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$J,
  inputs: ['contentId', 'disabled', 'maxEdgeStart', 'menuId', 'side', 'swipeGesture', 'type'],
  methods: ['isOpen', 'isActive', 'open', 'close', 'toggle', 'setOpen']
})], IonMenu);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonMenu,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['contentId', 'disabled', 'maxEdgeStart', 'menuId', 'side', 'swipeGesture', 'type'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonMenuButton = class IonMenuButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonMenuButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonMenuButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonMenuButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonMenuButton,
  isStandalone: true,
  selector: "ion-menu-button",
  inputs: {
    autoHide: "autoHide",
    color: "color",
    disabled: "disabled",
    menu: "menu",
    mode: "mode",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonMenuButton = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$K,
  inputs: ['autoHide', 'color', 'disabled', 'menu', 'mode', 'type']
})], IonMenuButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonMenuButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-menu-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autoHide', 'color', 'disabled', 'menu', 'mode', 'type'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonMenuToggle = class IonMenuToggle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonMenuToggle.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonMenuToggle,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonMenuToggle.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonMenuToggle,
  isStandalone: true,
  selector: "ion-menu-toggle",
  inputs: {
    autoHide: "autoHide",
    menu: "menu"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonMenuToggle = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$L,
  inputs: ['autoHide', 'menu']
})], IonMenuToggle);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonMenuToggle,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-menu-toggle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autoHide', 'menu'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonNavLink = class IonNavLink {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonNavLink.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonNavLink,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonNavLink.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonNavLink,
  isStandalone: true,
  selector: "ion-nav-link",
  inputs: {
    component: "component",
    componentProps: "componentProps",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonNavLink = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$M,
  inputs: ['component', 'componentProps', 'routerAnimation', 'routerDirection']
})], IonNavLink);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonNavLink,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-nav-link',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['component', 'componentProps', 'routerAnimation', 'routerDirection'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonNote = class IonNote {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonNote.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonNote,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonNote.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonNote,
  isStandalone: true,
  selector: "ion-note",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonNote = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$N,
  inputs: ['color', 'mode']
})], IonNote);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonNote,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-note',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonPicker = class IonPicker {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionPickerDidPresent', 'ionPickerWillPresent', 'ionPickerWillDismiss', 'ionPickerDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonPicker.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonPicker,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonPicker.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonPicker,
  isStandalone: true,
  selector: "ion-picker",
  inputs: {
    animated: "animated",
    backdropDismiss: "backdropDismiss",
    buttons: "buttons",
    columns: "columns",
    cssClass: "cssClass",
    duration: "duration",
    enterAnimation: "enterAnimation",
    htmlAttributes: "htmlAttributes",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    mode: "mode",
    showBackdrop: "showBackdrop",
    trigger: "trigger"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonPicker = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$O,
  inputs: ['animated', 'backdropDismiss', 'buttons', 'columns', 'cssClass', 'duration', 'enterAnimation', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'showBackdrop', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss', 'getColumn']
})], IonPicker);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonPicker,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-picker',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'backdropDismiss', 'buttons', 'columns', 'cssClass', 'duration', 'enterAnimation', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'showBackdrop', 'trigger'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonProgressBar = class IonProgressBar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonProgressBar.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonProgressBar,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonProgressBar.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonProgressBar,
  isStandalone: true,
  selector: "ion-progress-bar",
  inputs: {
    buffer: "buffer",
    color: "color",
    mode: "mode",
    reversed: "reversed",
    type: "type",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonProgressBar = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$P,
  inputs: ['buffer', 'color', 'mode', 'reversed', 'type', 'value']
})], IonProgressBar);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonProgressBar,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-progress-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['buffer', 'color', 'mode', 'reversed', 'type', 'value'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRefresher = class IonRefresher {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionRefresh', 'ionPull', 'ionStart']);
  }
};
/** @nocollapse */
IonRefresher.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRefresher,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRefresher.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRefresher,
  isStandalone: true,
  selector: "ion-refresher",
  inputs: {
    closeDuration: "closeDuration",
    disabled: "disabled",
    mode: "mode",
    pullFactor: "pullFactor",
    pullMax: "pullMax",
    pullMin: "pullMin",
    snapbackDuration: "snapbackDuration"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRefresher = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$Q,
  inputs: ['closeDuration', 'disabled', 'mode', 'pullFactor', 'pullMax', 'pullMin', 'snapbackDuration'],
  methods: ['complete', 'cancel', 'getProgress']
})], IonRefresher);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRefresher,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-refresher',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['closeDuration', 'disabled', 'mode', 'pullFactor', 'pullMax', 'pullMin', 'snapbackDuration'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRefresherContent = class IonRefresherContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonRefresherContent.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRefresherContent,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRefresherContent.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRefresherContent,
  isStandalone: true,
  selector: "ion-refresher-content",
  inputs: {
    pullingIcon: "pullingIcon",
    pullingText: "pullingText",
    refreshingSpinner: "refreshingSpinner",
    refreshingText: "refreshingText"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRefresherContent = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$R,
  inputs: ['pullingIcon', 'pullingText', 'refreshingSpinner', 'refreshingText']
})], IonRefresherContent);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRefresherContent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-refresher-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['pullingIcon', 'pullingText', 'refreshingSpinner', 'refreshingText'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonReorder = class IonReorder {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonReorder.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonReorder,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonReorder.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonReorder,
  isStandalone: true,
  selector: "ion-reorder",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonReorder = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$S
})], IonReorder);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonReorder,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-reorder',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonReorderGroup = class IonReorderGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionItemReorder']);
  }
};
/** @nocollapse */
IonReorderGroup.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonReorderGroup,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonReorderGroup.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonReorderGroup,
  isStandalone: true,
  selector: "ion-reorder-group",
  inputs: {
    disabled: "disabled"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonReorderGroup = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$T,
  inputs: ['disabled'],
  methods: ['complete']
})], IonReorderGroup);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonReorderGroup,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-reorder-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRippleEffect = class IonRippleEffect {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonRippleEffect.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRippleEffect,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRippleEffect.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRippleEffect,
  isStandalone: true,
  selector: "ion-ripple-effect",
  inputs: {
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRippleEffect = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$U,
  inputs: ['type'],
  methods: ['addRipple']
})], IonRippleEffect);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRippleEffect,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-ripple-effect',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['type'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRow = class IonRow {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonRow.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRow,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRow.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRow,
  isStandalone: true,
  selector: "ion-row",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRow = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$V
})], IonRow);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRow,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-row',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSegmentButton = class IonSegmentButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSegmentButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSegmentButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSegmentButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonSegmentButton,
  isStandalone: true,
  selector: "ion-segment-button",
  inputs: {
    disabled: "disabled",
    layout: "layout",
    mode: "mode",
    type: "type",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSegmentButton = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$W,
  inputs: ['disabled', 'layout', 'mode', 'type', 'value']
})], IonSegmentButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSegmentButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-segment-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'layout', 'mode', 'type', 'value'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSelectOption = class IonSelectOption {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSelectOption.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSelectOption,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSelectOption.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonSelectOption,
  isStandalone: true,
  selector: "ion-select-option",
  inputs: {
    disabled: "disabled",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSelectOption = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$X,
  inputs: ['disabled', 'value']
})], IonSelectOption);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSelectOption,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-select-option',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'value'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSkeletonText = class IonSkeletonText {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSkeletonText.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSkeletonText,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSkeletonText.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonSkeletonText,
  isStandalone: true,
  selector: "ion-skeleton-text",
  inputs: {
    animated: "animated"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSkeletonText = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$Y,
  inputs: ['animated']
})], IonSkeletonText);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSkeletonText,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-skeleton-text',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSpinner = class IonSpinner {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSpinner.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSpinner,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSpinner.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonSpinner,
  isStandalone: true,
  selector: "ion-spinner",
  inputs: {
    color: "color",
    duration: "duration",
    name: "name",
    paused: "paused"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSpinner = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$Z,
  inputs: ['color', 'duration', 'name', 'paused']
})], IonSpinner);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSpinner,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-spinner',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'duration', 'name', 'paused'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSplitPane = class IonSplitPane {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionSplitPaneVisible']);
  }
};
/** @nocollapse */
IonSplitPane.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSplitPane,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSplitPane.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonSplitPane,
  isStandalone: true,
  selector: "ion-split-pane",
  inputs: {
    contentId: "contentId",
    disabled: "disabled",
    when: "when"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSplitPane = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$_,
  inputs: ['contentId', 'disabled', 'when']
})], IonSplitPane);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSplitPane,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-split-pane',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['contentId', 'disabled', 'when'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonTabBar = class IonTabBar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonTabBar.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTabBar,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonTabBar.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonTabBar,
  isStandalone: true,
  selector: "ion-tab-bar",
  inputs: {
    color: "color",
    mode: "mode",
    selectedTab: "selectedTab",
    translucent: "translucent"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonTabBar = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$$,
  inputs: ['color', 'mode', 'selectedTab', 'translucent']
})], IonTabBar);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTabBar,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-tab-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode', 'selectedTab', 'translucent'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonTabButton = class IonTabButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonTabButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTabButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonTabButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonTabButton,
  isStandalone: true,
  selector: "ion-tab-button",
  inputs: {
    disabled: "disabled",
    download: "download",
    href: "href",
    layout: "layout",
    mode: "mode",
    rel: "rel",
    selected: "selected",
    tab: "tab",
    target: "target"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonTabButton = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$10,
  inputs: ['disabled', 'download', 'href', 'layout', 'mode', 'rel', 'selected', 'tab', 'target']
})], IonTabButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTabButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-tab-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'download', 'href', 'layout', 'mode', 'rel', 'selected', 'tab', 'target'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonText = class IonText {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonText.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonText,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonText.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonText,
  isStandalone: true,
  selector: "ion-text",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonText = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$11,
  inputs: ['color', 'mode']
})], IonText);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonText,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-text',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonThumbnail = class IonThumbnail {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonThumbnail.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonThumbnail,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonThumbnail.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonThumbnail,
  isStandalone: true,
  selector: "ion-thumbnail",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonThumbnail = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$12
})], IonThumbnail);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonThumbnail,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-thumbnail',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: [],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonTitle = class IonTitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonTitle.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTitle,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonTitle.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonTitle,
  isStandalone: true,
  selector: "ion-title",
  inputs: {
    color: "color",
    size: "size"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonTitle = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$13,
  inputs: ['color', 'size']
})], IonTitle);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTitle,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-title',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'size'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonToast = class IonToast {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionToastDidPresent', 'ionToastWillPresent', 'ionToastWillDismiss', 'ionToastDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonToast.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonToast,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonToast.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonToast,
  isStandalone: true,
  selector: "ion-toast",
  inputs: {
    animated: "animated",
    buttons: "buttons",
    color: "color",
    cssClass: "cssClass",
    duration: "duration",
    enterAnimation: "enterAnimation",
    header: "header",
    htmlAttributes: "htmlAttributes",
    icon: "icon",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    layout: "layout",
    leaveAnimation: "leaveAnimation",
    message: "message",
    mode: "mode",
    position: "position",
    positionAnchor: "positionAnchor",
    swipeGesture: "swipeGesture",
    translucent: "translucent",
    trigger: "trigger"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonToast = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$14,
  inputs: ['animated', 'buttons', 'color', 'cssClass', 'duration', 'enterAnimation', 'header', 'htmlAttributes', 'icon', 'isOpen', 'keyboardClose', 'layout', 'leaveAnimation', 'message', 'mode', 'position', 'positionAnchor', 'swipeGesture', 'translucent', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss']
})], IonToast);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonToast,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-toast',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'buttons', 'color', 'cssClass', 'duration', 'enterAnimation', 'header', 'htmlAttributes', 'icon', 'isOpen', 'keyboardClose', 'layout', 'leaveAnimation', 'message', 'mode', 'position', 'positionAnchor', 'swipeGesture', 'translucent', 'trigger'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonToolbar = class IonToolbar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonToolbar.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonToolbar,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonToolbar.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonToolbar,
  isStandalone: true,
  selector: "ion-toolbar",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonToolbar = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$15,
  inputs: ['color', 'mode']
})], IonToolbar);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonToolbar,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-toolbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});

// eslint-disable-next-line @angular-eslint/component-class-suffix
class IonTabs extends IonTabs$1 {}
/** @nocollapse */
IonTabs.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTabs,
  deps: null,
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonTabs.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonTabs,
  isStandalone: true,
  selector: "ion-tabs",
  queries: [{
    propertyName: "tabBar",
    first: true,
    predicate: IonTabBar,
    descendants: true
  }, {
    propertyName: "tabBars",
    predicate: IonTabBar
  }],
  viewQueries: [{
    propertyName: "outlet",
    first: true,
    predicate: ["outlet"],
    descendants: true,
    read: IonRouterOutlet
  }],
  usesInheritance: true,
  ngImport: i0,
  template: `
    <ng-content select="[slot=top]"></ng-content>
    <div class="tabs-inner" #tabsInner>
      <ion-router-outlet
        #outlet
        tabs="true"
        (stackWillChange)="onStackWillChange($event)"
        (stackDidChange)="onStackDidChange($event)"
      ></ion-router-outlet>
    </div>
    <ng-content></ng-content>
  `,
  isInline: true,
  styles: [":host{display:flex;position:absolute;inset:0;flex-direction:column;width:100%;height:100%;contain:layout size style}.tabs-inner{position:relative;flex:1;contain:layout size style}\n"],
  dependencies: [{
    kind: "directive",
    type: IonRouterOutlet,
    selector: "ion-router-outlet"
  }]
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTabs,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-tabs',
      template: `
    <ng-content select="[slot=top]"></ng-content>
    <div class="tabs-inner" #tabsInner>
      <ion-router-outlet
        #outlet
        tabs="true"
        (stackWillChange)="onStackWillChange($event)"
        (stackDidChange)="onStackDidChange($event)"
      ></ion-router-outlet>
    </div>
    <ng-content></ng-content>
  `,
      standalone: true,
      imports: [IonRouterOutlet],
      styles: [":host{display:flex;position:absolute;inset:0;flex-direction:column;width:100%;height:100%;contain:layout size style}.tabs-inner{position:relative;flex:1;contain:layout size style}\n"]
    }]
  }],
  propDecorators: {
    outlet: [{
      type: ViewChild,
      args: ['outlet', {
        read: IonRouterOutlet,
        static: false
      }]
    }],
    tabBar: [{
      type: ContentChild,
      args: [IonTabBar, {
        static: false
      }]
    }],
    tabBars: [{
      type: ContentChildren,
      args: [IonTabBar]
    }]
  }
});
class ModalController extends OverlayBaseController {
  constructor() {
    super(modalController);
    this.angularDelegate = inject(AngularDelegate);
    this.injector = inject(Injector);
    this.environmentInjector = inject(EnvironmentInjector);
    defineCustomElement$3();
  }
  create(opts) {
    return super.create({
      ...opts,
      delegate: this.angularDelegate.create(this.environmentInjector, this.injector, 'modal')
    });
  }
}
/** @nocollapse */
ModalController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ModalController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
ModalController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ModalController
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ModalController,
  decorators: [{
    type: Injectable
  }],
  ctorParameters: function () {
    return [];
  }
});
class PopoverController extends OverlayBaseController {
  constructor() {
    super(popoverController);
    this.angularDelegate = inject(AngularDelegate);
    this.injector = inject(Injector);
    this.environmentInjector = inject(EnvironmentInjector);
    defineCustomElement$4();
  }
  create(opts) {
    return super.create({
      ...opts,
      delegate: this.angularDelegate.create(this.environmentInjector, this.injector, 'popover')
    });
  }
}
const provideIonicAngular = config => {
  /**
   * TODO FW-4967
   * Use makeEnvironmentProviders once Angular 14 support is dropped.
   * This prevents provideIonicAngular from being accidentally referenced in an @Component.
   */
  return [{
    provide: ConfigToken,
    useValue: config
  }, {
    provide: APP_INITIALIZER,
    useFactory: initializeIonicAngular,
    multi: true,
    deps: [ConfigToken, DOCUMENT]
  }, provideComponentInputBinding(), AngularDelegate, ModalController, PopoverController];
};
const initializeIonicAngular = (config, doc) => {
  return () => {
    /**
     * By default Ionic Framework hides elements that
     * are not hydrated, but in the CE build there is no
     * hydration.
     * TODO FW-2797: Remove when all integrations have been
     * migrated to CE build.
     */
    doc.documentElement.classList.add('ion-ce');
    initialize(config);
  };
};
class ActionSheetController extends OverlayBaseController {
  constructor() {
    super(actionSheetController);
    defineCustomElement$7();
  }
}
/** @nocollapse */
ActionSheetController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ActionSheetController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
ActionSheetController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ActionSheetController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ActionSheetController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
class AlertController extends OverlayBaseController {
  constructor() {
    super(alertController);
    defineCustomElement$8();
  }
}
/** @nocollapse */
AlertController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: AlertController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
AlertController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: AlertController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: AlertController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
class AnimationController {
  /**
   * Create a new animation
   */
  create(animationId) {
    return createAnimation(animationId);
  }
  /**
   * EXPERIMENTAL
   *
   * Given a progression and a cubic bezier function,
   * this utility returns the time value(s) at which the
   * cubic bezier reaches the given time progression.
   *
   * If the cubic bezier never reaches the progression
   * the result will be an empty array.
   *
   * This is most useful for switching between easing curves
   * when doing a gesture animation (i.e. going from linear easing
   * during a drag, to another easing when `progressEnd` is called)
   */
  easingTime(p0, p1, p2, p3, progression) {
    return getTimeGivenProgression(p0, p1, p2, p3, progression);
  }
}
/** @nocollapse */
AnimationController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: AnimationController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
AnimationController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: AnimationController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: AnimationController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }]
});
class GestureController {
  constructor(zone) {
    this.zone = zone;
  }
  /**
   * Create a new gesture
   */
  create(opts, runInsideAngularZone = false) {
    if (runInsideAngularZone) {
      Object.getOwnPropertyNames(opts).forEach(key => {
        if (typeof opts[key] === 'function') {
          const fn = opts[key];
          opts[key] = (...props) => this.zone.run(() => fn(...props));
        }
      });
    }
    return createGesture(opts);
  }
}
/** @nocollapse */
GestureController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: GestureController,
  deps: [{
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
GestureController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: GestureController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: GestureController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.NgZone
    }];
  }
});
class LoadingController extends OverlayBaseController {
  constructor() {
    super(loadingController);
    defineCustomElement$I();
  }
}
/** @nocollapse */
LoadingController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: LoadingController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
LoadingController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: LoadingController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: LoadingController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
class MenuController extends MenuController$1 {
  constructor() {
    super(menuController);
  }
}
/** @nocollapse */
MenuController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: MenuController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
MenuController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: MenuController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: MenuController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
class PickerController extends OverlayBaseController {
  constructor() {
    super(pickerController);
    defineCustomElement$O();
  }
}
/** @nocollapse */
PickerController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: PickerController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
PickerController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: PickerController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: PickerController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
class ToastController extends OverlayBaseController {
  constructor() {
    super(toastController);
    defineCustomElement$14();
  }
}
/** @nocollapse */
ToastController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ToastController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
ToastController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ToastController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ToastController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
let IonNav = class IonNav extends IonNav$1 {
  constructor(ref, environmentInjector, injector, angularDelegate, z, c) {
    super(ref, environmentInjector, injector, angularDelegate, z, c);
  }
};
/** @nocollapse */
IonNav.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonNav,
  deps: [{
    token: i0.ElementRef
  }, {
    token: i0.EnvironmentInjector
  }, {
    token: i0.Injector
  }, {
    token: i2$1.AngularDelegate
  }, {
    token: i0.NgZone
  }, {
    token: i0.ChangeDetectorRef
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonNav.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonNav,
  isStandalone: true,
  selector: "ion-nav",
  usesInheritance: true,
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true
});
IonNav = __decorate([ProxyCmp$1({
  defineCustomElementFn: defineCustomElement$16
})], IonNav);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonNav,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-nav',
      template: '<ng-content></ng-content>',
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.EnvironmentInjector
    }, {
      type: i0.Injector
    }, {
      type: i2$1.AngularDelegate
    }, {
      type: i0.NgZone
    }, {
      type: i0.ChangeDetectorRef
    }];
  }
});
const CHECKBOX_INPUTS = ['checked', 'color', 'disabled', 'indeterminate', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$a = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonCheckbox),
  multi: true
};
let IonCheckbox = class IonCheckbox extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionFocus', 'ionBlur']);
  }
  writeValue(value) {
    this.elementRef.nativeElement.checked = this.lastValue = value;
    setIonicClasses(this.elementRef);
  }
  handleIonChange(el) {
    this.handleValueChange(el, el.checked);
  }
};
/** @nocollapse */
IonCheckbox.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCheckbox,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }, {
    token: i0.Injector
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCheckbox.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonCheckbox,
  isStandalone: true,
  selector: "ion-checkbox",
  inputs: {
    checked: "checked",
    color: "color",
    disabled: "disabled",
    indeterminate: "indeterminate",
    justify: "justify",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    mode: "mode",
    name: "name",
    value: "value"
  },
  host: {
    listeners: {
      "ionChange": "handleIonChange($event.target)"
    }
  },
  providers: [accessorProvider$a],
  usesInheritance: true,
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCheckbox = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$17,
  inputs: CHECKBOX_INPUTS
})], IonCheckbox);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCheckbox,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-checkbox',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: CHECKBOX_INPUTS,
      providers: [accessorProvider$a],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  },
  propDecorators: {
    handleIonChange: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  }
});
const DATETIME_INPUTS = ['cancelText', 'clearText', 'color', 'dayValues', 'disabled', 'doneText', 'firstDayOfWeek', 'highlightedDates', 'hourCycle', 'hourValues', 'isDateEnabled', 'locale', 'max', 'min', 'minuteValues', 'mode', 'monthValues', 'multiple', 'name', 'preferWheel', 'presentation', 'readonly', 'showClearButton', 'showDefaultButtons', 'showDefaultTimeLabel', 'showDefaultTitle', 'size', 'titleSelectedDatesFormatter', 'value', 'yearValues'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.

 */
const accessorProvider$9 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonDatetime),
  multi: true
};
let IonDatetime = class IonDatetime extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionCancel', 'ionChange', 'ionFocus', 'ionBlur']);
  }
  handleIonChange(el) {
    this.handleValueChange(el, el.value);
  }
};
/** @nocollapse */
IonDatetime.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonDatetime,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }, {
    token: i0.Injector
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonDatetime.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonDatetime,
  isStandalone: true,
  selector: "ion-datetime",
  inputs: {
    cancelText: "cancelText",
    clearText: "clearText",
    color: "color",
    dayValues: "dayValues",
    disabled: "disabled",
    doneText: "doneText",
    firstDayOfWeek: "firstDayOfWeek",
    highlightedDates: "highlightedDates",
    hourCycle: "hourCycle",
    hourValues: "hourValues",
    isDateEnabled: "isDateEnabled",
    locale: "locale",
    max: "max",
    min: "min",
    minuteValues: "minuteValues",
    mode: "mode",
    monthValues: "monthValues",
    multiple: "multiple",
    name: "name",
    preferWheel: "preferWheel",
    presentation: "presentation",
    readonly: "readonly",
    showClearButton: "showClearButton",
    showDefaultButtons: "showDefaultButtons",
    showDefaultTimeLabel: "showDefaultTimeLabel",
    showDefaultTitle: "showDefaultTitle",
    size: "size",
    titleSelectedDatesFormatter: "titleSelectedDatesFormatter",
    value: "value",
    yearValues: "yearValues"
  },
  host: {
    listeners: {
      "ionChange": "handleIonChange($event.target)"
    }
  },
  providers: [accessorProvider$9],
  usesInheritance: true,
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonDatetime = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$18,
  inputs: DATETIME_INPUTS,
  methods: ['confirm', 'reset', 'cancel']
})], IonDatetime);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonDatetime,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-datetime',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: DATETIME_INPUTS,
      providers: [accessorProvider$9],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  },
  propDecorators: {
    handleIonChange: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  }
});
let IonIcon = class IonIcon {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonIcon.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonIcon,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonIcon.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonIcon,
  isStandalone: true,
  selector: "ion-icon",
  inputs: {
    color: "color",
    flipRtl: "flipRtl",
    icon: "icon",
    ios: "ios",
    lazy: "lazy",
    md: "md",
    mode: "mode",
    name: "name",
    sanitize: "sanitize",
    size: "size",
    src: "src"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonIcon = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$19,
  inputs: ['color', 'flipRtl', 'icon', 'ios', 'lazy', 'md', 'mode', 'name', 'sanitize', 'size', 'src']
})], IonIcon);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonIcon,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'flipRtl', 'icon', 'ios', 'lazy', 'md', 'mode', 'name', 'sanitize', 'size', 'src'],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
const INPUT_INPUTS = ['accept', 'autocapitalize', 'autocomplete', 'autocorrect', 'autofocus', 'clearInput', 'clearOnEdit', 'color', 'counter', 'counterFormatter', 'debounce', 'disabled', 'enterkeyhint', 'errorText', 'fill', 'helperText', 'inputmode', 'label', 'labelPlacement', 'legacy', 'max', 'maxlength', 'min', 'minlength', 'mode', 'multiple', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'shape', 'size', 'spellcheck', 'step', 'type', 'value'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$8 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonInput),
  multi: true
};
let IonInput = class IonInput extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionInput', 'ionChange', 'ionBlur', 'ionFocus']);
  }
  handleIonInput(el) {
    this.handleValueChange(el, el.value);
  }
  registerOnChange(fn) {
    super.registerOnChange(value => {
      if (this.type === 'number') {
        /**
         * If the input type is `number`, we need to convert the value to a number
         * when the value is not empty. If the value is empty, we want to treat
         * the value as null.
         */
        fn(value === '' ? null : parseFloat(value));
      } else {
        fn(value);
      }
    });
  }
};
/** @nocollapse */
IonInput.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonInput,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }, {
    token: i0.Injector
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonInput.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonInput,
  isStandalone: true,
  selector: "ion-input",
  inputs: {
    accept: "accept",
    autocapitalize: "autocapitalize",
    autocomplete: "autocomplete",
    autocorrect: "autocorrect",
    autofocus: "autofocus",
    clearInput: "clearInput",
    clearOnEdit: "clearOnEdit",
    color: "color",
    counter: "counter",
    counterFormatter: "counterFormatter",
    debounce: "debounce",
    disabled: "disabled",
    enterkeyhint: "enterkeyhint",
    errorText: "errorText",
    fill: "fill",
    helperText: "helperText",
    inputmode: "inputmode",
    label: "label",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    max: "max",
    maxlength: "maxlength",
    min: "min",
    minlength: "minlength",
    mode: "mode",
    multiple: "multiple",
    name: "name",
    pattern: "pattern",
    placeholder: "placeholder",
    readonly: "readonly",
    required: "required",
    shape: "shape",
    size: "size",
    spellcheck: "spellcheck",
    step: "step",
    type: "type",
    value: "value"
  },
  host: {
    listeners: {
      "ionInput": "handleIonInput($event.target)"
    }
  },
  providers: [accessorProvider$8],
  usesInheritance: true,
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonInput = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1a,
  inputs: INPUT_INPUTS,
  methods: ['setFocus', 'getInputElement']
})], IonInput);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonInput,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: INPUT_INPUTS,
      providers: [accessorProvider$8],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  },
  propDecorators: {
    handleIonInput: [{
      type: HostListener,
      args: ['ionInput', ['$event.target']]
    }]
  }
});
const RADIO_GROUP_INPUTS = ['allowEmptySelection', 'name', 'value'];
/**
 * Pulling the provider into an object and using PURE  works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$7 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonRadioGroup),
  multi: true
};
let IonRadioGroup = class IonRadioGroup extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange']);
  }
  handleIonChange(el) {
    this.handleValueChange(el, el.value);
  }
};
/** @nocollapse */
IonRadioGroup.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRadioGroup,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }, {
    token: i0.Injector
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRadioGroup.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRadioGroup,
  isStandalone: true,
  selector: "ion-radio-group",
  inputs: {
    allowEmptySelection: "allowEmptySelection",
    name: "name",
    value: "value"
  },
  host: {
    listeners: {
      "ionChange": "handleIonChange($event.target)"
    }
  },
  providers: [accessorProvider$7],
  usesInheritance: true,
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRadioGroup = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1b,
  inputs: RADIO_GROUP_INPUTS
})], IonRadioGroup);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRadioGroup,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-radio-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: RADIO_GROUP_INPUTS,
      providers: [accessorProvider$7],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  },
  propDecorators: {
    handleIonChange: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  }
});
const RADIO_INPUTS = ['color', 'disabled', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$6 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonRadio),
  multi: true
};
let IonRadio = class IonRadio extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
  handleIonSelect(el) {
    /**
     * The `el` type is any to access the `checked` state property
     * that is not exposed on the type interface.
     */
    this.handleValueChange(el, el.checked);
  }
};
/** @nocollapse */
IonRadio.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRadio,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }, {
    token: i0.Injector
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRadio.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRadio,
  isStandalone: true,
  selector: "ion-radio",
  inputs: {
    color: "color",
    disabled: "disabled",
    justify: "justify",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    mode: "mode",
    name: "name",
    value: "value"
  },
  host: {
    listeners: {
      "ionSelect": "handleIonSelect($event.target)"
    }
  },
  providers: [accessorProvider$6],
  usesInheritance: true,
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRadio = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1c,
  inputs: RADIO_INPUTS
})], IonRadio);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRadio,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-radio',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: RADIO_INPUTS,
      providers: [accessorProvider$6],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  },
  propDecorators: {
    handleIonSelect: [{
      type: HostListener,
      args: ['ionSelect', ['$event.target']]
    }]
  }
});
const RANGE_INPUTS = ['activeBarStart', 'color', 'debounce', 'disabled', 'dualKnobs', 'label', 'labelPlacement', 'legacy', 'max', 'min', 'mode', 'name', 'pin', 'pinFormatter', 'snaps', 'step', 'ticks', 'value'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$5 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonRange),
  multi: true
};
let IonRange = class IonRange extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionInput', 'ionFocus', 'ionBlur', 'ionKnobMoveStart', 'ionKnobMoveEnd']);
  }
  handleIonInput(el) {
    this.handleValueChange(el, el.value);
  }
};
/** @nocollapse */
IonRange.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRange,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }, {
    token: i0.Injector
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRange.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRange,
  isStandalone: true,
  selector: "ion-range",
  inputs: {
    activeBarStart: "activeBarStart",
    color: "color",
    debounce: "debounce",
    disabled: "disabled",
    dualKnobs: "dualKnobs",
    label: "label",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    max: "max",
    min: "min",
    mode: "mode",
    name: "name",
    pin: "pin",
    pinFormatter: "pinFormatter",
    snaps: "snaps",
    step: "step",
    ticks: "ticks",
    value: "value"
  },
  host: {
    listeners: {
      "ionInput": "handleIonInput($event.target)"
    }
  },
  providers: [accessorProvider$5],
  usesInheritance: true,
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRange = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1d,
  inputs: RANGE_INPUTS
})], IonRange);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRange,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-range',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: RANGE_INPUTS,
      providers: [accessorProvider$5],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  },
  propDecorators: {
    handleIonInput: [{
      type: HostListener,
      args: ['ionInput', ['$event.target']]
    }]
  }
});
const SEARCHBAR_INPUTS = ['animated', 'autocomplete', 'autocorrect', 'cancelButtonIcon', 'cancelButtonText', 'clearIcon', 'color', 'debounce', 'disabled', 'enterkeyhint', 'inputmode', 'mode', 'name', 'placeholder', 'searchIcon', 'showCancelButton', 'showClearButton', 'spellcheck', 'type', 'value'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$4 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonSearchbar),
  multi: true
};
let IonSearchbar = class IonSearchbar extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionInput', 'ionChange', 'ionCancel', 'ionClear', 'ionBlur', 'ionFocus']);
  }
  handleIonInput(el) {
    this.handleValueChange(el, el.value);
  }
};
/** @nocollapse */
IonSearchbar.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSearchbar,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }, {
    token: i0.Injector
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSearchbar.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonSearchbar,
  isStandalone: true,
  selector: "ion-searchbar",
  inputs: {
    animated: "animated",
    autocomplete: "autocomplete",
    autocorrect: "autocorrect",
    cancelButtonIcon: "cancelButtonIcon",
    cancelButtonText: "cancelButtonText",
    clearIcon: "clearIcon",
    color: "color",
    debounce: "debounce",
    disabled: "disabled",
    enterkeyhint: "enterkeyhint",
    inputmode: "inputmode",
    mode: "mode",
    name: "name",
    placeholder: "placeholder",
    searchIcon: "searchIcon",
    showCancelButton: "showCancelButton",
    showClearButton: "showClearButton",
    spellcheck: "spellcheck",
    type: "type",
    value: "value"
  },
  host: {
    listeners: {
      "ionInput": "handleIonInput($event.target)"
    }
  },
  providers: [accessorProvider$4],
  usesInheritance: true,
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSearchbar = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1e,
  inputs: SEARCHBAR_INPUTS,
  methods: ['setFocus', 'getInputElement']
})], IonSearchbar);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSearchbar,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-searchbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: SEARCHBAR_INPUTS,
      providers: [accessorProvider$4],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  },
  propDecorators: {
    handleIonInput: [{
      type: HostListener,
      args: ['ionInput', ['$event.target']]
    }]
  }
});
const SEGMENT_INPUTS = ['color', 'disabled', 'mode', 'scrollable', 'selectOnFocus', 'swipeGesture', 'value'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$3 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonSegment),
  multi: true
};
let IonSegment = class IonSegment extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange']);
  }
  handleIonChange(el) {
    this.handleValueChange(el, el.value);
  }
};
/** @nocollapse */
IonSegment.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSegment,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }, {
    token: i0.Injector
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSegment.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonSegment,
  isStandalone: true,
  selector: "ion-segment",
  inputs: {
    color: "color",
    disabled: "disabled",
    mode: "mode",
    scrollable: "scrollable",
    selectOnFocus: "selectOnFocus",
    swipeGesture: "swipeGesture",
    value: "value"
  },
  host: {
    listeners: {
      "ionChange": "handleIonChange($event.target)"
    }
  },
  providers: [accessorProvider$3],
  usesInheritance: true,
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSegment = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1f,
  inputs: SEGMENT_INPUTS
})], IonSegment);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSegment,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-segment',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: SEGMENT_INPUTS,
      providers: [accessorProvider$3],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  },
  propDecorators: {
    handleIonChange: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  }
});
const SELECT_INPUTS = ['cancelText', 'color', 'compareWith', 'disabled', 'expandedIcon', 'fill', 'interface', 'interfaceOptions', 'justify', 'label', 'labelPlacement', 'legacy', 'mode', 'multiple', 'name', 'okText', 'placeholder', 'selectedText', 'shape', 'toggleIcon', 'value'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$2 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonSelect),
  multi: true
};
let IonSelect = class IonSelect extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionCancel', 'ionDismiss', 'ionFocus', 'ionBlur']);
  }
  handleIonChange(el) {
    this.handleValueChange(el, el.value);
  }
};
/** @nocollapse */
IonSelect.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSelect,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }, {
    token: i0.Injector
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSelect.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonSelect,
  isStandalone: true,
  selector: "ion-select",
  inputs: {
    cancelText: "cancelText",
    color: "color",
    compareWith: "compareWith",
    disabled: "disabled",
    expandedIcon: "expandedIcon",
    fill: "fill",
    interface: "interface",
    interfaceOptions: "interfaceOptions",
    justify: "justify",
    label: "label",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    mode: "mode",
    multiple: "multiple",
    name: "name",
    okText: "okText",
    placeholder: "placeholder",
    selectedText: "selectedText",
    shape: "shape",
    toggleIcon: "toggleIcon",
    value: "value"
  },
  host: {
    listeners: {
      "ionChange": "handleIonChange($event.target)"
    }
  },
  providers: [accessorProvider$2],
  usesInheritance: true,
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSelect = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1g,
  inputs: SELECT_INPUTS,
  methods: ['open']
})], IonSelect);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSelect,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-select',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: SELECT_INPUTS,
      providers: [accessorProvider$2],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  },
  propDecorators: {
    handleIonChange: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  }
});
const TEXTAREA_INPUTS = ['autoGrow', 'autocapitalize', 'autofocus', 'clearOnEdit', 'color', 'cols', 'counter', 'counterFormatter', 'debounce', 'disabled', 'enterkeyhint', 'errorText', 'fill', 'helperText', 'inputmode', 'label', 'labelPlacement', 'legacy', 'maxlength', 'minlength', 'mode', 'name', 'placeholder', 'readonly', 'required', 'rows', 'shape', 'spellcheck', 'value', 'wrap'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider$1 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonTextarea),
  multi: true
};
let IonTextarea = class IonTextarea extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionInput', 'ionBlur', 'ionFocus']);
  }
  handleIonInput(el) {
    this.handleValueChange(el, el.value);
  }
};
/** @nocollapse */
IonTextarea.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTextarea,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }, {
    token: i0.Injector
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonTextarea.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonTextarea,
  isStandalone: true,
  selector: "ion-textarea",
  inputs: {
    autoGrow: "autoGrow",
    autocapitalize: "autocapitalize",
    autofocus: "autofocus",
    clearOnEdit: "clearOnEdit",
    color: "color",
    cols: "cols",
    counter: "counter",
    counterFormatter: "counterFormatter",
    debounce: "debounce",
    disabled: "disabled",
    enterkeyhint: "enterkeyhint",
    errorText: "errorText",
    fill: "fill",
    helperText: "helperText",
    inputmode: "inputmode",
    label: "label",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    maxlength: "maxlength",
    minlength: "minlength",
    mode: "mode",
    name: "name",
    placeholder: "placeholder",
    readonly: "readonly",
    required: "required",
    rows: "rows",
    shape: "shape",
    spellcheck: "spellcheck",
    value: "value",
    wrap: "wrap"
  },
  host: {
    listeners: {
      "ionInput": "handleIonInput($event.target)"
    }
  },
  providers: [accessorProvider$1],
  usesInheritance: true,
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonTextarea = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1h,
  inputs: TEXTAREA_INPUTS,
  methods: ['setFocus', 'getInputElement']
})], IonTextarea);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTextarea,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-textarea',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: TEXTAREA_INPUTS,
      providers: [accessorProvider$1],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  },
  propDecorators: {
    handleIonInput: [{
      type: HostListener,
      args: ['ionInput', ['$event.target']]
    }]
  }
});
const TOGGLE_INPUTS = ['checked', 'color', 'disabled', 'enableOnOffLabels', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value'];
/**
 * Pulling the provider into an object and using PURE works
 * around an ng-packagr issue that causes
 * components with multiple decorators and
 * a provider to be re-assigned. This re-assignment
 * is not supported by Webpack and causes treeshaking
 * to not work on these kinds of components.
 */
const accessorProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: /*@__PURE__*/forwardRef(() => IonToggle),
  multi: true
};
let IonToggle = class IonToggle extends ValueAccessor {
  constructor(c, r, z, injector) {
    super(injector, r);
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionFocus', 'ionBlur']);
  }
  writeValue(value) {
    this.elementRef.nativeElement.checked = this.lastValue = value;
    setIonicClasses(this.elementRef);
  }
  handleIonChange(el) {
    this.handleValueChange(el, el.checked);
  }
};
/** @nocollapse */
IonToggle.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonToggle,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }, {
    token: i0.Injector
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonToggle.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonToggle,
  isStandalone: true,
  selector: "ion-toggle",
  inputs: {
    checked: "checked",
    color: "color",
    disabled: "disabled",
    enableOnOffLabels: "enableOnOffLabels",
    justify: "justify",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    mode: "mode",
    name: "name",
    value: "value"
  },
  host: {
    listeners: {
      "ionChange": "handleIonChange($event.target)"
    }
  },
  providers: [accessorProvider],
  usesInheritance: true,
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonToggle = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1i,
  inputs: TOGGLE_INPUTS
})], IonToggle);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonToggle,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-toggle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: TOGGLE_INPUTS,
      providers: [accessorProvider],
      standalone: true
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }];
  },
  propDecorators: {
    handleIonChange: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  }
});

/**
 * Generated bundle index. Do not edit.
 */

export { ActionSheetController, AlertController, AnimationController, GestureController, IonAccordion, IonAccordionGroup, IonActionSheet, IonAlert, IonApp, IonAvatar, IonBackButton, IonBackdrop, IonBadge, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonChip, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonFab, IonFabButton, IonFabList, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonItemGroup, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonLoading, IonMenu, IonMenuButton, IonMenuToggle, IonModal, IonNav, IonNavLink, IonNote, IonPicker, IonPopover, IonProgressBar, IonRadio, IonRadioGroup, IonRange, IonRefresher, IonRefresherContent, IonReorder, IonReorderGroup, IonRippleEffect, IonRouterLink, IonRouterLinkWithHref, IonRouterOutlet, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonSkeletonText, IonSpinner, IonSplitPane, IonTabBar, IonTabButton, IonTabs, IonText, IonTextarea, IonThumbnail, IonTitle, IonToast, IonToggle, IonToolbar, LoadingController, MenuController, ModalController, PickerController, PopoverController, ToastController, provideIonicAngular };

/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatform } from '@ionic/angular/standalone';
import { createClient, SupabaseClient, User } from '@supabase/supabase-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { SupabaseService } from "./supabase.service";

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private currentUser: BehaviorSubject<User | boolean> = new BehaviorSubject(null);

    constructor(private router: Router, private supabase: SupabaseService) {
        // this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey, {
        //   auth: {
        //     autoRefreshToken: false, // All my Supabase access is from server, so no need to refresh the token
        //     detectSessionInUrl: false, // We are not using OAuth, so we don't need this. Also, we are manually "detecting" the session in the server-side code
        //     persistSession: false, // All our access is from server, so no need to persist the session to browser's local storage
        //   },
        // });

        this.supabase.client.auth.onAuthStateChange((event, sess) => {
            console.log('SUPABAS AUTH CHANGED: ', event);
            console.log('SUPABAS AUTH CHANGED sess: ', sess);

            if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
                console.log('SET USER');

                this.currentUser.next(sess.user);
            } else {
                this.currentUser.next(false);
            }
        });

        this.loadUser();
    }

    async loadUser() {
        if (this.currentUser.value) {
            console.log('ALREADY GOT USER');
            return;
        }
        const user = await this.supabase.client.auth.getUser();
        console.log('🚀 ~ file: auth.service.ts ~ line 33 ~ AuthService ~ loadUser ~ session', user);

        if (user.data.user) {
            this.currentUser.next(user.data.user);
        } else {
            this.currentUser.next(false);
        }
    }

    signUp(credentials: { email; password }) {
        return this.supabase.client.auth.signUp(credentials);
    }

    signIn(credentials: { email; password }) {
        return this.supabase.client.auth.signInWithPassword(credentials);
    }

    signInWithEmail(email: string) {
        const redirectTo = isPlatform('capacitor') ? 'supachat://login' : `${window.location.origin}/events`;
        console.log('set redirect: ', redirectTo);

        return this.supabase.client.auth.signInWithOtp({ email, options: { emailRedirectTo: redirectTo } });
    }

    sendPwReset(email) {
        return this.supabase.client.auth.resetPasswordForEmail(email);
    }

    async signOut() {
        await this.supabase.client.auth.signOut();
        this.router.navigateByUrl('/', { replaceUrl: true });
    }

    getCurrentUser(): Observable<User | boolean> {
        return this.currentUser.asObservable();
    }

    getCurrentUserId(): string {
        if (this.currentUser.value) {
            return (this.currentUser.value as User).id;
        } else {
            return null;
        }
    }

    async setSession(access_token, refresh_token) {
        return this.supabase.client.auth.setSession({ access_token, refresh_token });
    }
}
